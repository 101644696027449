<template>
    <v-card flat height="470" class="mb-7">
        <v-card-text class="pa-5">
            <div class="d-md-flex align-items-center">
                <div>
                    <span class="lstick"></span>
                    <h3 class="title font-weight-regular">Contactos Recientes</h3>
                </div>
                <div class="ml-auto">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <a  href="javascript:void(0)"
                                class="link"
                                dark
                                v-bind="attrs"
                                v-on="on">
                                    <i class="fas fa-ellipsis-v"></i>
                            </a>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, index) in items" :key="index">
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
            
            <v-list class="mt-4">
                <v-list-item v-for="(item, i) in messages" :key="i" @click="href">
                    <v-list-item-title>
                        <div class="d-flex align-center py-3">
                            <div class="mr-3">
                                <v-badge
                                        bordered
                                        top
                                        color="primary"
                                        dot
                                        offset-x="10"
                                        offset-y="10">
                                    <v-avatar
                                            :color="getColor()"
                                            size="48"
                                            class="white--text">
                                        {{ item.name | initials }}
                                    </v-avatar>
                                </v-badge>
                            </div>
                            <div class="mx-3">
                                <h5 class="font-weight-medium">{{ item.name }}</h5>
                                <span
                                    class="text--secondary descpart d-block truncate-text subtitle-2">
                                    {{ item.email }}
                                </span>
                            </div>
                        </div>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
    import dashboardService from '@/providers/DashboardService';

    export default {
        name: "TheMyContacts",
        data: () => ({
            items: [{title: "Edit"}, {title: "Update"}, {title: "Add"}],
            messages: [],
            href() {
                return undefined;
            },
        }),
        filters: {
            initials(value) {
                const name = value.split(' ');
                return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
            },
            trimText(value) {
                const name = value.trim();
                return name;
            }
        },
        methods: {
            genRandomIndex (length) {
                return Math.ceil(Math.random() * (length - 1))
            },
            getColor() {
                let colors = [
                    "info",
                    "success",
                    "primary",
                    "teal",
                    "deep-purple accent2",
                    "red lighten-1",
                    "pink darken-2",
                    "grey darken-2",
                    "brown",
                    "blue-grey darken-3",
                    "red darken-3",
                    "green"
                ];
                const colorsLength = colors.length;
                let colorsel = this.genRandomIndex(colorsLength);
                return colors[colorsel];
            },
            getMessages() {
                dashboardService.getRecientContacts().then(record => {
                    this.messages = record.value;
                });
            },
        },
        mounted() {
            this.messages = [];
            this.getMessages();
        }
    };
</script>